<template>
    <!-- Momento 1 es pedido -->
    <chat
    :desfase="0"
    :momento="2"
    finalizado
    :limitar-mensajes="false"
    :tipo="2"
    :user="id_pedido"
    :fecha-finalizado="detalle.fecha_finalizado_chat"
    />
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed:{
        ...mapGetters({
            id_pedido: 'reclamos/reclamos/id_pedido',
            detalle: 'reclamos/reclamos/detalle'
        }),

    },

}
</script>

<style lang="css" scoped>
</style>
